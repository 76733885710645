<template>
  <div class="section-wish m-0 px-3 px-sm-4 px-md-5 py-5" id="wishes">
    <h1 class="h1-couple text-center mb-3">Wishes</h1>
    <div class="d-grid gap-2 w-100">
      <input
        type="text"
        id="guestname"
        class="form-control"
        placeholder="Nama Tamu"
        v-model="nama"
      />
      <textarea
        name="messages"
        id="messages"
        class="form-control"
        placeholder="Berikan ucapan Selamat & Do'a"
        cols="40"
        rows="6"
        v-model="ucapan"
      ></textarea>
      <button @click="sendMessage" class="btn btn-hy-secondary btn-lg">
        Kirim
      </button>
    </div>
    <ul class="list-group mt-5" :style="logWishes">
      <li class="list-group-item" v-for="(item, index) in guest" :key="index">
        <span class="fw-bold">{{ item.nama }}</span
        ><br />
        {{ item.ucapan }}
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
  name: "WishesSection",
  data() {
    return {
      guest: [],
      nama: "",
      ucapan: "",
    };
  },
  mounted() {
    axios
      .get("https://sheetdb.io/api/v1/y4nbaqpxqonlu")
      .then((response) => (this.guest = response.data));
  },
  computed: {
    logWishes() {
      return this.guest.length > 4 ? "height: 25rem;overflow: scroll" : "";
    },
  },
  methods: {
    sendMessage() {
      let messages = {
        nama: this.nama,
        ucapan: this.ucapan,
      };

      let saveMessage = {
        data: [messages],
      };

      this.guest.push(messages);
      axios.post("https://sheetdb.io/api/v1/y4nbaqpxqonlu", saveMessage);
      Vue.$toast.success("Berhasil Mengirim Ucapan");
      this.nama = "";
      this.ucapan = "";
    },
  },
};
</script>
